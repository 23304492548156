/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { EntityBase } from 'models/entity'
import React from 'react'
// 3th package

// Services
import { FC } from 'react'

interface Props {
  data: EntityBase[]
  getTitle: (a: any) => string
  getCaption: (a: any) => string
}
export const GridList: FC<Props> = ({ data, getTitle, getCaption }) => {
  const maxLen = 60
  return (
    <Grid container columnSpacing={2}>
      {data.map((item) => {
        const title = getTitle(item)
        const caption = getCaption(item)
        return (
          <Grid item xs={4}>
            <Box sx={{ px: 1, py: 1, borderBottomWidth: '1px', borderBlockColor: '#EEE' }}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500', color: '#333' }}>{title}</Typography>
              {!caption || caption.length < maxLen ? (
                <Typography sx={{ fontSize: '12px', height: '36px', lineHeight: '16px', color: '#999', overflow: 'hidden' }}>{caption ?? ''}</Typography>
              ) : (
                <Tooltip title={caption}>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      height: '36px',
                      lineHeight: '16px',
                      color: '#999',
                      overflow: 'hidden',
                      '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: '#eee'
                      }
                    }}
                  >
                    {caption.substring(0, maxLen) + '...'}
                  </Typography>
                </Tooltip>
              )}
            </Box>
          </Grid>
        )
      })}
    </Grid>
  )
}
