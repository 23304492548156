/* eslint-disable @typescript-eslint/naming-convention */
import { Checkbox, Radio, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import { IconButton } from 'components'
import { EnumTable } from 'models/table'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { tableActions } from 'store'
import { useTableConfig, useTableData } from './hook'

interface Props {
  data: any
  code: EnumTable
  onAction?: (action: string, item: any) => void
}

export function RowItem({ data, code, onAction }: Props) {
  const { config } = useTableConfig({ code })
  const { table } = useTableData(code)
  const columns = config?.columns ?? []
  const dispatch = useDispatch()
  // TODO
  const in_items = table?.select?.items.find((item) => item.id === data.id) !== undefined
  const select_all = table?.select?.select_all ?? false
  const selected = (in_items && !select_all) || (!in_items && select_all)

  const selectRow = useCallback(
    (item: any) => {
      dispatch(tableActions.selectRow({ item, code }))
    },
    [code, dispatch]
  )

  return (
    <TableRow sx={{}}>
      {config?.select && (
        <TableCell>
          {config.select?.multiple ? (
            <Checkbox checked={selected} onClick={() => selectRow(data)} />
          ) : (
            <Radio checked={selected} onClick={() => selectRow(data)} />
          )}
        </TableCell>
      )}
      {columns.map(({ cell }) => {
        const { key, width, getValue, getElement } = cell
        if (getElement) {
          return <TableCell>{getElement(data)}</TableCell>
        }
        //  TODO
        const max_len = width ? width / 2 : 50
        const label = key ? data[key] ?? '' : getValue ? `${getValue(data)}` : ''
        const trimmed = label.length > max_len
        const trimLabel = trimmed ? `${label.slice(0, max_len)}...` : label
        return (
          <TableCell size="small" sx={{ cursor: trimmed ? 'pointer' : null, minWidth: width ? `${width}px` : '140px' }} align="center">
            {trimmed ? (
              <Tooltip title={label} placement="top" followCursor arrow>
                <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#333' }}>{trimLabel}</Typography>
              </Tooltip>
            ) : (
              <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#333' }}>{label}</Typography>
            )}
          </TableCell>
        )
      })}
      {config?.row_actions && config.row_actions.length > 0 && (
        <TableCell sx={{ flexDirection: 'row' }}>
          {config.row_actions.map((action) => {
            const row_data = typeof action === 'function' ? action(data) : action
            return (
              <IconButton
                {...row_data}
                onClick={() => {
                  if (row_data.onClick) {
                    row_data.onClick(data)
                  } else if (row_data.id !== undefined) {
                    onAction?.(row_data.id, data)
                  }
                }}
              />
            )
          })}
        </TableCell>
      )}
    </TableRow>
  )
}
