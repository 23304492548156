import { GroupPermissionEntity, TableColumn } from 'models'

const Table: TableColumn<GroupPermissionEntity>[] = [
  {
    header: {
      label: 'Tên'
    },
    cell: {
      key: 'name',
      width: 100
    }
  }
]
export default Table
