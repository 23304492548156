import { EntityBase } from '../entity'
import { PermissionEntity } from './permission'

export interface GroupPermissionEntity extends EntityBase {
  name: string
  role?: EnumDefaultRole
  description: string
  permissions?: PermissionEntity[]
}

export enum EnumDefaultRole {
  ADMIN = 1,
  BUSINESS_MANAGER = 2,
  STORE_MANAGER = 3,
  STORE_EMPLOYEE = 4,
  BACK_OFFICE_EMPLOYEE = 5,
  BACK_OFFICE_MANAGER = 6
}

export const DefaultRoleLabel: Record<EnumDefaultRole, string> = {
  [EnumDefaultRole.ADMIN]: 'Admin',
  [EnumDefaultRole.BUSINESS_MANAGER]: 'QL vùng',
  [EnumDefaultRole.STORE_MANAGER]: 'QL cửa hàng',
  [EnumDefaultRole.STORE_EMPLOYEE]: 'NV cửa hàng',
  [EnumDefaultRole.BACK_OFFICE_EMPLOYEE]: 'NV BackOffice',
  [EnumDefaultRole.BACK_OFFICE_MANAGER]: 'Trưởng phòng BackOffice'
}
