import React from 'react'

export function Role() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 4.75H8C7.59 4.75 7.25 4.41 7.25 4C7.25 3.59 7.59 3.25 8 3.25H15C15.41 3.25 15.75 3.59 15.75 4C15.75 4.41 15.41 4.75 15 4.75Z" fill="white" />
      <path
        d="M15 19.75H13.5C11.98 19.75 10.75 18.52 10.75 17V4C10.75 3.59 11.09 3.25 11.5 3.25C11.91 3.25 12.25 3.59 12.25 4V17C12.25 17.69 12.81 18.25 13.5 18.25H15C15.41 18.25 15.75 18.59 15.75 19C15.75 19.41 15.41 19.75 15 19.75Z"
        fill="white"
      />
      <path
        d="M15 12.25H11.5C11.09 12.25 10.75 11.91 10.75 11.5C10.75 11.09 11.09 10.75 11.5 10.75H15C15.41 10.75 15.75 11.09 15.75 11.5C15.75 11.91 15.41 12.25 15 12.25Z"
        fill="white"
      />
      <path
        d="M6 7.75H3C1.48 7.75 0.25 6.52 0.25 5V3C0.25 1.48 1.48 0.25 3 0.25H6C7.52 0.25 8.75 1.48 8.75 3V5C8.75 6.52 7.52 7.75 6 7.75ZM3 1.75C2.31 1.75 1.75 2.31 1.75 3V5C1.75 5.69 2.31 6.25 3 6.25H6C6.69 6.25 7.25 5.69 7.25 5V3C7.25 2.31 6.69 1.75 6 1.75H3Z"
        fill="white"
      />
      <path
        d="M19.8 6.75H16.2C15.12 6.75 14.25 5.86999 14.25 4.79999V3.20001C14.25 2.12001 15.13 1.25 16.2 1.25H19.8C20.88 1.25 21.75 2.13001 21.75 3.20001V4.79999C21.75 5.87999 20.88 6.75 19.8 6.75ZM16.2 2.75C15.96 2.75 15.75 2.96001 15.75 3.20001V4.79999C15.75 5.03999 15.96 5.25 16.2 5.25H19.8C20.04 5.25 20.25 5.03999 20.25 4.79999V3.20001C20.25 2.96001 20.04 2.75 19.8 2.75H16.2Z"
        fill="white"
      />
      <path
        d="M19.8 14.25H16.2C15.12 14.25 14.25 13.37 14.25 12.3V10.7C14.25 9.62001 15.13 8.75 16.2 8.75H19.8C20.88 8.75 21.75 9.63001 21.75 10.7V12.3C21.75 13.38 20.88 14.25 19.8 14.25ZM16.2 10.25C15.96 10.25 15.75 10.46 15.75 10.7V12.3C15.75 12.54 15.96 12.75 16.2 12.75H19.8C20.04 12.75 20.25 12.54 20.25 12.3V10.7C20.25 10.46 20.04 10.25 19.8 10.25H16.2Z"
        fill="white"
      />
      <path
        d="M19.8 21.75H16.2C15.12 21.75 14.25 20.87 14.25 19.8V18.2C14.25 17.12 15.13 16.25 16.2 16.25H19.8C20.88 16.25 21.75 17.13 21.75 18.2V19.8C21.75 20.88 20.88 21.75 19.8 21.75ZM16.2 17.75C15.96 17.75 15.75 17.96 15.75 18.2V19.8C15.75 20.04 15.96 20.25 16.2 20.25H19.8C20.04 20.25 20.25 20.04 20.25 19.8V18.2C20.25 17.96 20.04 17.75 19.8 17.75H16.2Z"
        fill="white"
      />
    </svg>
  )
}
