import { useSelector } from 'hooks'
import React, { ReactNode } from 'react'
import { CookiesCustom } from 'utils'

interface Props {
  children: ReactNode
}
export function ProtectedAuth({ children }: Props) {
  const { user } = useSelector((x) => x.profile)
  // TODO

  const token = CookiesCustom.getToken()

  if (!token) {
    // window.location.href = `${process.env.REACT_APP_LOGIN_PAGE}/login?redirect_url=${process.env.REACT_APP_OKR_PAGE}${location.pathname}`
  }

  return <>{children}</>
}
