import { EntityBase } from './entity'
import { StoreEntity } from './store'

export interface AreaEntity extends EntityBase {
  name: string
  code: string
  brand: EnumAreaBrand
  slug: string
  stores?: StoreEntity[]
  deleted_at: Date
}
export enum EnumAreaBrand {
  TokyoLife = 'Tokyolife',
  Format = 'Format'
}
