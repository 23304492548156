/* eslint-disable @typescript-eslint/naming-convention */
import { Box, MenuItem, Select, Typography, useMediaQuery } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import { EnumTable } from 'models'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { tableActions } from 'store'
import { PerPageOptions, useTableData } from './hook'

interface Props {
  code: EnumTable
}
export function MPagination({ code }: Props) {
  const { pagination, pageIndex, pageSize } = useTableData(code)
  const { per_page, last_page, current_page, total, from, to } = pagination ?? {}
  const dispatch = useDispatch()
  const changeIndex = useCallback(
    (a: number) => {
      if (pageIndex === a) {
        return
      }
      dispatch(tableActions.setPageIndex({ code, value: a }))
    },
    [code, dispatch, pageIndex]
  )

  const changeSize = useCallback(
    (a: number) => {
      if (pageSize === a) {
        return
      }
      dispatch(tableActions.setPageSize({ code, value: a }))
    },
    [code, dispatch, pageSize]
  )

  const matches = useMediaQuery('(max-width:600px)')
  return (
    <Box
      sx={{
        height: `${matches ? 'auto' : '52px'}`,
        display: `${matches ? 'block' : 'flex'}`,
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: '1px solid #EEEEEE',
        '& p, & button': {
          fontFamily: 'Be Vietnam Pro, sans-serif',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '20px',
          color: '#737373'
        },
        '& button': {
          color: '#333333'
        },
        p: `${matches ? '8px 12px' : '0 18px'}`
      }}
    >
      {matches && (
        <Box
          sx={{
            marginBottom: '12px',
            justifyContent: 'center',
            '& .MuiPagination-ul li': {
              margin: '0 4px',
              minWidth: '28px',
              height: '28px',
              backgroundColor: 'rgba(255, 255, 255, 1e-05);',
              border: '1px solif #EEEEEE',
              borderRadius: '4px',
              color: '#333333'
            },
            '& .MuiPagination-ul': {
              justifyContent: 'center'
            },
            '& .Mui-selected': {
              backgroundColor: '#DB1B33 !important',
              border: '1px solid #DB1B33',
              color: 'white'
            },
            '& .MuiPaginationItem-previousNext': {
              border: 'none'
            }
          }}
        >
          <Pagination
            page={current_page || 1}
            count={last_page}
            variant="outlined"
            shape="rounded"
            size="small"
            onChange={(_, page) => {
              if (current_page === page) {
                return
              }
              changeIndex(page)
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: `${matches ? 'center' : 'center'}`
          }}
        >
          <Typography>Số hàng trên trang: </Typography>
          <Select
            sx={{
              height: '28px',
              padding: '3px 5px 3px 12px',
              minWidth: '65px',
              ml: 1,
              border: '1px solid #EEEEEE',
              borderRadius: '6px',
              '& fieldset': {
                border: 'none'
              },
              '& div:first-child': {
                p: 0,
                paddingRight: '0 !important'
              }
            }}
            size="small"
            value={`${per_page}`}
            onChange={(e) => {
              const size = Number(e.target.value)
              if (pageSize === size) {
                return
              }
              changeIndex(1)
              changeSize(size)
            }}
          >
            {PerPageOptions.map((item: number) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            textAlign: 'center'
          }}
        >
          {from != null && to != null && from <= to ? (
            <Typography>{!matches ? ` Hiển thị ${from}-${to} trên tổng sổ ${total}` : `Hiển thị ${from}-${to} / ${total}`}</Typography>
          ) : (
            <Typography>{!matches ? ` Hiển thị 0-0 trên tổng sổ ${total}` : `Hiển thị 0-0 / ${total}`}</Typography>
          )}
        </Box>
        {!matches && (
          <Box
            sx={{
              m: `${matches ? '12px 0' : ''}`,
              justifyContent: 'center',
              '& .MuiPagination-ul li': {
                margin: '0 4px',
                minWidth: '28px',
                height: '28px',
                backgroundColor: 'rgba(255, 255, 255, 1e-05);',
                border: '1px solif #EEEEEE',
                borderRadius: '4px',
                color: '#333333'
              },
              '& .Mui-selected': {
                backgroundColor: '#DB1B33 !important',
                border: '1px solid #DB1B33',
                color: 'white'
              },
              '& .MuiPaginationItem-previousNext': {
                border: 'none'
              }
            }}
          >
            <Pagination
              page={current_page || 1}
              count={last_page || 1}
              variant="outlined"
              shape="rounded"
              size="small"
              onChange={(e, page) => {
                if (pageSize === page) {
                  return
                }
                changeIndex(page)
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}
