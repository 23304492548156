import { TableColumn, UserEntity } from 'models'

const Table: TableColumn<UserEntity>[] = [
  {
    header: {
      label: 'Họ tên'
    },
    cell: {
      key: 'name'
    }
  },
  {
    header: {
      label: 'Tên tài khoản'
    },
    cell: {
      key: 'username'
    }
  },
  {
    header: {
      label: 'Nhóm quyền'
    },
    cell: {
      getValue: (a: UserEntity) => a.group_permissions?.map((item) => item.name).join(', ') ?? ''
    }
  },
  {
    header: {
      label: 'Chức vụ'
    },
    cell: {
      getValue: (a: UserEntity) => a.position ?? ''
    }
  },
  {
    header: {
      label: 'Phòng ban'
    },
    cell: {
      key: 'company',
      width: 200
    }
  }
]

export default Table
