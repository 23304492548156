import { showToast } from 'components'
import { useCallback, useState } from 'react'
import isEqual from 'react-fast-compare'
import { useSelector as useReduxSelector } from 'react-redux'
import Api from 'services'
import { PortalSyncModel } from 'services/portal'
import { RootState } from 'store'

function useSelector<T>(selector: (state: RootState) => T, equalityFn = isEqual): T {
  return useReduxSelector<RootState, T>(selector, equalityFn)
}
function useAuth(): boolean {
  const { user } = useSelector((x) => x.profile)
  return user !== undefined
}

function useSyncPortal({ data_type }: { data_type: 'store' | 'user' | 'org_unit' }) {
  const [enableSync, setEnableSync] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)

  const getSyncModel = useCallback((): PortalSyncModel | null => {
    if (data_type === 'store') {
      return 'store'
    }
    if (data_type === 'user') {
      return 'user'
    }
    if (data_type === 'org_unit') {
      return 'organization_unit'
    }
    return null
  }, [data_type])

  const syncPortal = useCallback(() => {
    const model = getSyncModel()
    if (!model) {
      return
    }
    if (!enableSync) {
      return
    }
    setEnableSync(false)
    setLoading(true)

    Api.portal
      .sync(model)
      .then(() => {
        showToast({
          content: 'Đồng bộ từ Portal thành công',
          type: 'success'
        })
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
    setTimeout(() => {
      setEnableSync(true)
    }, 10000)
  }, [enableSync, getSyncModel])

  return { syncPortal, loading, enableSync }
}
export { useSelector, useAuth, useSyncPortal }
