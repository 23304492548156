import { Box } from '@mui/material'
import { EnumTable } from 'models'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { tableActions } from 'store/slices/table'
import { useTableConfig, useTableData } from '../hook'
import { TagItem } from './tag_item'

interface Props {
  code: EnumTable
}
export function TagList({ code }: Props) {
  const { config } = useTableConfig({ code })
  const { table } = useTableData(code)
  const filter_data = table?.filter?.data ?? {}
  const dispatch = useDispatch()
  const handleDeleteFilter = useCallback(
    (field: string) => {
      dispatch(tableActions.changeFilter({ name: code, data: { [field]: null } as any }))
    },
    [code, dispatch]
  )

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        mt: '10px',
        flexWrap: 'wrap'
      }}
    >
      {config?.filters?.inputs.map(({ name, hidden, label, getLabel }) => {
        const value = filter_data[name]
        if (value == null) {
          return <></>
        }
        const valueLabel = Array.isArray(value) ? value.map((i) => getLabel?.(i) ?? i).join(', ') : getLabel?.(value) ?? value
        return <TagItem label={label ? `${label}:` : ''} value={valueLabel} onDelete={hidden ? undefined : () => handleDeleteFilter(name)} />
      })}
    </Box>
  )
}
