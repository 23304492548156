import { CustomerCareEntity, ListEntity, StatCustomerCare } from 'models'
import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'

const index = async (params: ApiRequest.customer_care.index): Promise<ListEntity<CustomerCareEntity>> => instanceAxios.get('/customer-cares', { params })

const show = async (id: string): Promise<any> => instanceAxios.get(`customer-cares/${id}`)

const mapStore = async (campaign: string): Promise<any> => instanceAxios.post(`customer-cares/map-store`, { campaign })

const stats = (params: ApiRequest.customer_care.stats): Promise<any> =>
  instanceAxios.get('customer-cares/statistic', {
    params
  })
export default {
  stats,
  mapStore,
  index,
  show
}
