import { CampaignEntity, CampaignTypeLabel, TableColumn } from 'models'
import { formatDateEn } from 'utils'
const Table: TableColumn<CampaignEntity>[] = [
  {
    header: {
      label: 'Tên chiến dịch'
    },
    cell: {
      key: 'name',
      width: 100
    }
  },
  {
    header: {
      label: 'Đối tượng'
    },
    cell: { getValue: (a: CampaignEntity) => (a.type != undefined ? CampaignTypeLabel[a.type] : '') }
  },
  {
    header: {
      label: 'Số lượng khách hàng'
    },
    cell: { getValue: (a: CampaignEntity) => (a.customer_count == null ? '' : a.customer_count) }
  },
  {
    header: {
      label: 'Thời gian tạo'
    },
    cell: { getValue: (a: CampaignEntity) => formatDateEn(a.created_at) }
  },
  {
    header: {
      label: 'Người tạo'
    },
    cell: { getValue: (a: CampaignEntity) => a.created_by?.name }
  },
  {
    header: {
      label: 'Mô tả'
    },
    cell: {
      key: 'description'
    }
  }
]
export default Table
