import React, { lazy } from 'react'

import { Loadable } from 'components'
import { MenuUrl } from 'models'

const RoleList = Loadable(lazy(() => import('./pages/list')))
const RoleDetail = Loadable(lazy(() => import('./pages/detail')))
const RoleUsers = Loadable(lazy(() => import('./pages/users')))

export const GroupPermissionRoutes: { path: string; element: React.JSX.Element }[] = [
  {
    path: MenuUrl.role,
    element: <RoleList />
  },
  {
    path: `${MenuUrl.role}/:id/users`,
    element: <RoleUsers />
  },
  {
    path: `${MenuUrl.role}/:id`,
    element: <RoleDetail />
  }
]
