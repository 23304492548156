import { instanceAxios } from 'utils'
import users from './group_permission_user'
import { ApiRequest } from './interface'

const index = async (): Promise<any> => instanceAxios.get('/group-permissions')

const show = async (id: string): Promise<any> => instanceAxios.get(`/group-permissions/${id}`)

const create = async (data: ApiRequest.group_permission.create) => instanceAxios.post('/group-permissions', data)

const update = async (id: string, data: ApiRequest.group_permission.update) => instanceAxios.patch(`/group-permissions/${id}`, data)

export default {
  index,
  show,
  create,
  update,
  users
}
