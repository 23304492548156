import React from 'react'
export const UploadV2 = () => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.3203 6.07053C22.0206 5.98455 17.5857 8.44679 16.3691 14.0026C11.4178 12.3126 6.94723 15.6458 7.41162 20.968C4.821 22.0151 3 24.546 3 27.5207C3 31.4377 6.15334 34.591 10.0703 34.591H18.6592C18.6568 32.2106 18.6518 27.4342 18.6518 26.5295L16.6377 27.2594C15.1765 27.8892 14.0972 26.0491 15.3486 24.9034L19.1596 21.1998C20.0301 20.3294 21.0697 20.4292 21.8403 21.1998L25.6514 24.9034C26.9027 26.0491 25.8235 27.8892 24.3623 27.2594L22.3481 26.5295V34.591H30.9297C34.8467 34.591 38 31.4377 38 27.5207C38 24.6378 36.2888 22.1733 33.8252 21.0705C38.5988 11.7642 32.6045 6.19601 26.3203 6.07053Z"
        fill="#75B5FF"
      />
      <path
        d="M26.3204 6.06972C26.0809 6.065 25.818 6.07322 25.5782 6.08385C20.0865 14.7387 28.5737 21.0692 33.8253 21.0692C38.5989 11.7629 32.6046 6.1952 26.3204 6.06972Z"
        fill="#488BFF"
      />
    </svg>
  )
}
