import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material'
import { EnumTable } from 'models'
import React from 'react'
import { useDispatch } from 'react-redux'
import { tableActions } from 'store'
import { useTableConfig, useTableData } from './hook'

export interface TableHeaderEntity {
  id: string
  label: string
}

export interface TableHeaderProps {
  code: EnumTable
}
export function TableHeader({ code }: TableHeaderProps) {
  const { config } = useTableConfig({ code })
  const dispatch = useDispatch()
  const { table, data, all_data } = useTableData(code)
  const columns = config?.columns ?? []

  return (
    <TableHead>
      <TableRow>
        {config?.select && (
          <TableCell width={80}>
            {config?.select?.header_select && (
              <Checkbox
                checked={table?.select?.select_all ?? false}
                onChange={() => {
                  const type = config?.select?.header_select
                  if (type === 'all_pages') {
                    dispatch(tableActions.selectRows({ items: all_data ?? [], code }))
                  } else if (type === 'current_page') {
                    dispatch(tableActions.selectRows({ items: data ?? [], code }))
                  }
                }}
              />
            )}
          </TableCell>
        )}
        {columns.map(({ header, cell }) => {
          const { label } = header
          return (
            <TableCell
              padding="checkbox"
              style={{
                whiteSpace: 'nowrap',
                fontWeight: 'bold'
              }}
              align="center"
              sx={{
                minWidth: cell.width ? `${cell.width}px` : '140px'
              }}
            >
              {label}
            </TableCell>
          )
        })}
        {config?.row_actions?.length && (
          <TableCell
            padding="checkbox"
            style={{
              whiteSpace: 'nowrap',
              fontWeight: 'bold'
            }}
          >
            Thao tác
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}
