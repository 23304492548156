import { TableColumn, UserEntity } from 'models'

const Table: TableColumn<UserEntity>[] = [
  {
    header: {
      label: 'Nhân viên'
    },
    cell: {
      key: 'name'
    }
  },
  {
    header: {
      label: 'Tài khoản'
    },
    cell: {
      key: 'username'
    }
  },
  {
    header: {
      label: 'Chức vụ'
    },
    cell: {
      getValue: (a: UserEntity) => a.position ?? '',
      width: 250
    }
  },
  {
    header: {
      label: 'Phòng ban'
    },
    cell: {
      key: 'company',
      width: 300
    }
  }
]

export default Table
