import { GroupPermissionEntity } from 'models'
import { instanceAxios } from 'utils'

const remove = async (id: string, users: string[]): Promise<GroupPermissionEntity> =>
  instanceAxios.delete(`group-permissions/${id}/users`, {
    data: { users }
  })
export default {
  remove
}
