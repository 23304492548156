import { Typography } from '@mui/material'
import React from 'react'

interface Props {
  label: string
  require?: boolean
}
export function InputLabel({ label, require }: Props) {
  return (
    <Typography
      sx={{
        fontFamily: 'Be Vietnam Pro, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#525252',
        marginBottom: '8px',
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      {label}
      {require && <Typography sx={{ color: '#FF3141', ml: '8px' }}>*</Typography>}
    </Typography>
  )
}
