/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ProfileStateEntity, StoreEntity, UserEntity } from 'models'

const initialState: ProfileStateEntity = {
  selected_stores: []
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setProfile: (state, { payload }: PayloadAction<UserEntity>) => {
      state.user = payload
      state.selected_stores = []
    },
    selectStore: (state, { payload }: PayloadAction<StoreEntity[]>) => {
      state.selected_stores = payload
    }
  }
})
export const { actions: profileActions, reducer: profileReducer } = slice
