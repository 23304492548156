import { ListEntity, OrgUnitEntity } from 'models'
import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'

const index = async (params: ApiRequest.org_unit.index): Promise<ListEntity<OrgUnitEntity>> =>
  instanceAxios.get('/organization-units', {
    params
  })
const show = async (id: string, params?: ApiRequest.org_unit.show): Promise<any> =>
  instanceAxios.get(`/organization-units/${id}`, {
    params
  })

export default {
  index,
  show
}
