import { Chip, Grid } from '@mui/material'
import React from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

interface Props {
  label: string
  value?: string
  onDelete?: () => void
}
export function TagItem({ label, value, onDelete }: Props) {
  if (!value) {
    return <></>
  }
  return (
    <Grid sx={{ mr: '12px', my: '5px' }} key="filter-code" item>
      <Chip
        icon={<b style={{ color: '#333' }}>{label}</b>}
        label={`${value}`}
        onClick={() => {}}
        onDelete={onDelete}
        sx={{
          borderRadius: '3px',
          padding: '2px 6px',
          border: '1px solid #2560E5',
          backgroundColor: '#EDF4FB',
          '&:hover': {
            backgroundColor: '#E5E7EB'
          }
        }}
        deleteIcon={<CloseOutlinedIcon />}
      />
    </Grid>
  )
}
