import { AppBar, Box, Toolbar } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { Profile } from './Profile'
import { useSelector } from 'hooks'

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

export function HeaderBar() {
  const theme = useTheme()

  // app-bar params
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxShadow: 'rgb(100 116 139 / 12%) 0px 1px 4px'
    }
  }

  return (
    <AppBar {...appBar}>
      <Toolbar sx={{ height: '40px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: '10px',
            width: '100%'
          }}
        >
          <Box />
          <Profile />
        </Box>
      </Toolbar>
    </AppBar>
  )
}
