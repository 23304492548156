import { AreaEntity, TableColumn } from 'models'

const Table: TableColumn<AreaEntity>[] = [
  {
    header: {
      label: 'Tên vùng'
    },
    cell: {
      key: 'name'
    }
  },
  {
    header: {
      label: 'Thương hiệu'
    },
    cell: {
      key: 'brand'
    }
  },
  {
    header: {
      label: 'Mã odoo'
    },
    cell: {
      key: 'odoo_code'
    }
  },
  {
    header: {
      label: 'Số lượng cửa hàng'
    },
    cell: {
      getValue: (a: AreaEntity) => `${a.stores?.length ?? 0} cửa hàng`
    }
  }
]

export default Table
