export const env = {
  api: {
    portal: process.env.REACT_APP_PORTAL_URL_API,
    customer_care: process.env.REACT_APP_API_CUSTOMER_CARE_URL
  },
  share_cookie_domain: process.env.REACT_APP_SHARE_COOKIE_DOMAIN,
  web: {
    forlife: process.env.REACT_APP_FORLIFE_HOME
  }
}
