import { AreaEntity, ListEntity } from 'models'
import { instanceAxios } from 'utils/axios'
import { ApiRequest } from './interface'

const index = async (params: ApiRequest.area.index): Promise<ListEntity<AreaEntity>> =>
  instanceAxios.get('/areas', {
    params
  })
export default {
  index
}
