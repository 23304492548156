import { CustomerEntity, CustomerGenderLabel, TableColumn } from 'models'
import { formatDateEn } from 'utils'

const Table: TableColumn<CustomerEntity>[] = [
  {
    header: {
      label: 'Tên khách hàng'
    },
    cell: {
      key: 'name'
    }
  },
  {
    header: {
      label: 'Mã bên ODoo'
    },
    cell: { key: 'odoo_code' }
  },
  {
    header: {
      label: 'Giới tính'
    },
    cell: {
      getValue: (a: CustomerEntity) => CustomerGenderLabel[a.gender]
    }
  },
  {
    header: {
      label: 'Số điện thoại'
    },
    cell: {
      key: 'phone'
    }
  },
  {
    header: {
      label: 'Sinh nhật'
    },
    cell: { getValue: (a: CustomerEntity) => formatDateEn(a.birthday) }
  },
  {
    header: {
      label: ' Chiến dịch'
    },
    cell: {
      getValue: (a: CustomerEntity) => a.campaigns?.map((item) => item.name).join(', ') ?? 'Chưa thuộc chiến dịch nào '
    }
  }
]
export default Table
