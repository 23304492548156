import { ListEntity, StoreEntity } from 'models'
import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'

const index = async (params: ApiRequest.store.index): Promise<ListEntity<StoreEntity>> => instanceAxios.get('/stores', { params })
const all = async (): Promise<ListEntity<StoreEntity>> => instanceAxios.get('/stores?all=true')

export default {
  all,
  index
}
