import { Avatar } from '@mui/material'
import { images } from 'assets'
import React from 'react'

interface Props {
  uri?: string
  style?: any
}
export function UiAvatar({ uri, style, ...other }: Props) {
  return <Avatar {...other} src={uri ?? images.default_avatar} sx={style} />
}
