import { AreaEntity } from './area'
import { EntityBase } from './entity'
import {OrgUnitEntity} from './org_unit'
import { UserEntity } from './user_portal'

export enum EnumStoreType {
  TokyoLife = 1,
  Format = 2,
  Office = 3
}
export const StoreTypeLabel: Record<EnumStoreType, string> = {
  [EnumStoreType.Format]: 'Format',
  [EnumStoreType.TokyoLife]: 'Tokyolife',
  [EnumStoreType.Office]: 'Văn phòng'
}
export interface StoreEntity extends EntityBase {
  name: string
  description: string
  area?: AreaEntity
  flag?: EnumStoreType
  org_unit?: OrgUnitEntity

  users?: UserEntity[]
  address: string
  source: string
  source_id: string
  odoo_code: string
  source_name: string
  source_phone: string
  source_lat: string
  source_long: string
  source_area: string
  source_city: string
  user?: UserEntity
  direction?: string
  location?: string
  type: number
  deleted_at: Date
}
