/* eslint-disable no-return-assign */
import { IconButton as MuiIconButton } from '@mui/material'
import { DetailIcon } from 'assets'
import React from 'react'
import { CustomTooltip } from './CustomTooltip'

export interface IconButtonProps {
  icon: React.JSX.Element
  onClick: (a?: any) => void
  label?: string
}
export function IconButton({ icon, label, onClick }: IconButtonProps) {
  return (
    <CustomTooltip title={label ?? ''} arrow>
      <MuiIconButton
        size="small"
        onClick={onClick}
        sx={{
          padding: '0',
          '&:hover': {
            padding: 0
          },

          borderRadius: '4px',
          width: '28px',
          height: '28px',
          m: '0 6px'
        }}
      >
        {icon}
      </MuiIconButton>
    </CustomTooltip>
  )
}

export const viewDetailAction = (getUrl: (a: any) => string): any => ({
  label: 'Xem chi tiết',
  icon: <DetailIcon />,
  onClick: (a: any) => (window.location.href = getUrl(a))
})
