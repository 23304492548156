import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IconButton } from './IconButton'
interface Props {
  title: string
  sub_title?: string
  rightView?: React.JSX.Element[] | React.JSX.Element
  enable_back?: boolean
  onBack?: () => void
}
export const HeaderView = ({ title, sub_title, rightView, enable_back, onBack }: Props) => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: enable_back ? 0 : '16px',
        paddingRight: '30px'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {enable_back && <IconButton icon={<ArrowBackIcon />} onClick={() => (onBack ? onBack() : navigate(-1))} label="" />}
          <Typography style={{ fontWeight: '700', fontSize: '22px', lineHeight: '28px', color: '#525252' }}>{title}</Typography>
        </Box>
        {sub_title && <p className="sub-title" dangerouslySetInnerHTML={{ __html: sub_title }} />}
      </Box>
      {rightView}
    </Box>
  )
}
