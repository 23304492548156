/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DistributeStateEntity, DistributeType, DistributeUnit, DistributeUnitType, OrgUnitEntity, StatCustomerCare } from 'models'
import { EntityBase } from 'models/entity'

const initialState: DistributeStateEntity = {
  distributed_amount: 0,
  distribute_period_amount: 0,
  total_amount: 0,
  units: [],
  distribute_type: null
}
const slice = createSlice({
  name: 'DISTRIBUTE_STATE',
  initialState,
  reducers: {
    setUnitCarer: (state, { payload }: PayloadAction<{ unit_carer: OrgUnitEntity }>) => {
      state.distributed_amount = 0
      state.distribute_type = null
      state.unit_carer = payload.unit_carer ?? undefined
    },
    setDistributePeriodAmount: (state, { payload }: PayloadAction<number>) => {
      if (payload * state.units.length > state.total_amount) {
        return
      }
      state.units.forEach((item, index) => {
        state.units[index].value = payload
      })
      state.distributed_amount = payload * state.units.length
      state.distribute_period_amount = payload
    },
    resetUnitCarer: (state) => {
      state.distributed_amount = 0
      state.distribute_type = null
      state.unit_carer = undefined
    },
    setStatistic: (state, { payload }: PayloadAction<StatCustomerCare>) => {
      state.statistic = { ...payload }
      state.distributed_amount = 0
    },
    setRefresh: (state, { payload }: PayloadAction<boolean>) => {
      state.refresh = payload
    },
    setOnlyShowDistributeUnits: (state, { payload }: PayloadAction<boolean>) => {
      state.only_show_distributed_units = payload
    },
    setTotalDistributeAmount: (state, { payload }: PayloadAction<number>) => {
      state.total_amount = payload
    },
    changeUnitValue: (state, { payload }: PayloadAction<{ id: string; value: number }>) => {
      const unitIndex = state.units.findIndex((item) => item.id === payload.id)
      if (unitIndex == -1) {
        return
      }
      const sum = payload.value + state.units.reduce((sum, a) => (sum += a.id === payload.id ? 0 : a.value), 0)
      if (sum > state.total_amount) {
        return
      }
      state.distributed_amount = state.distributed_amount - state.units[unitIndex].value + payload.value
      state.units[unitIndex].value = payload.value
    },
    initUnits: (state, { payload }: PayloadAction<{ units: EntityBase[]; type: DistributeUnitType }>) => {
      const arr: DistributeUnit[] = payload.units.map((item) => ({
        id: item.id,
        info: item,
        type: payload.type,
        value: 0
      }))
      state.units = arr
      state.distributed_amount = state.total_amount
      state.distribute_type = null
    },
    completeDistribute: (state) => {
      state.distribute_type = null
    },
    cancelDistribute: (state) => {
      state.distribute_type = null
      state.distributed_amount = state.total_amount
      state.units.forEach((_, index) => (state.units[index].value = 0))
    },
    startDistibute: (state, { payload }: PayloadAction<DistributeType | undefined>) => {
      if (payload === 'distribute_by_store') {
        state.distribute_type = 'distribute_by_store'
        const stores = state.statistic?.units ?? []
        state.distributed_amount = state.total_amount
        state.units.forEach((item, index) => {
          state.units[index].value = stores.find((store) => store.id === item.id)?.belong_count ?? 0
        })
        return
      }
      if (payload === 'distribute_by_period') {
        state.distribute_type = 'distribute_by_period'
        state.distribute_period_amount = 0
        state.distributed_amount = 0
        state.units.forEach((item, index) => {
          state.units[index].value = 0
        })
        return
      }
      const unitCount = state.units.length
      const unitValue = Math.floor(state.total_amount / unitCount)
      state.total_amount = state.total_amount
      state.units.forEach((item, index) => {
        state.units[index].value = unitValue
      })
      var distributed = unitCount * unitValue
      var i = 0
      while (distributed < state.total_amount && i < unitCount) {
        state.units[i].value++
        distributed++
        i++
      }
      state.distributed_amount = state.total_amount
      state.distribute_type = 'distribute_all_campaign'
    }
  }
})

export const { actions: distributeActions, reducer: distributeReducer } = slice
