/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CampaignEntity, CampaignStateEntity, EnumCampaignTab } from 'models'

const initialState: CampaignStateEntity = {
  tab: EnumCampaignTab.CUSTOMER_DATA
}
const slice = createSlice({
  name: 'CAMPAIGN_STATE',
  initialState,
  reducers: {
    setTab: (state, { payload }: PayloadAction<EnumCampaignTab>) => {
      state.tab = payload
    },
    selectCustomerCare: (state, { payload }: PayloadAction<string | null>) => {
      state.customer_care_id = payload ? payload : undefined
    },
    setCampaign: (state, { payload }: PayloadAction<CampaignEntity>) => {
      state.campaign = { ...payload }
    },
    updateCampaign: (state, { payload }: PayloadAction<Partial<CampaignEntity>>) => {
      if (!state.campaign) {
        return
      }
      if (state.campaign) {
        state.campaign = {
          ...state.campaign,
          ...payload
        }
      }
    }
  }
})
export const { actions: campaignActions, reducer: campaignReducer } = slice
