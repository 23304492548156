import { CampaignEntity } from 'models/campaign'
import { DistributeUnit, DistributeUnitType } from './distribute'

export interface CampaignStateEntity {
  campaign?: CampaignEntity
  tab: EnumCampaignTab
  customer_care_id?: string
}

export enum EnumCampaignTab {
  CUSTOMER_DATA,
  CUSTOMER_IMPORT,
  CUSTOMER_DISTRIBUTE,
  EMPLOYEE
}
