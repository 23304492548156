export interface ModuleEntity {
  module: EnumModule
  label: string
}

export enum EnumModule {
  GROUP_PERMISSION = 'group_permission',
  CAMPAIGN = 'campaign',
  CUSTOMER_CARE = 'customer_care',
  ORG_UNIT = 'org_unit',
  CUSTOMER = 'customer',
  LOCATION = 'location',
  USER = 'user'
}
export const ALL_MODULES: ModuleEntity[] = [
  {
    module: EnumModule.CAMPAIGN,
    label: 'Chiến dịch'
  },
  {
    module: EnumModule.CUSTOMER_CARE,
    label: 'Chăm sóc khách hàng'
  },
  {
    module: EnumModule.CUSTOMER,
    label: 'Khách hàng'
  },
  {
    module: EnumModule.USER,
    label: 'Nhân viên'
  },
  {
    module: EnumModule.ORG_UNIT,
    label: 'Cơ cấu tổ chức'
  },
  {
    module: EnumModule.LOCATION,
    label: 'Vị trí'
  },
  {
    module: EnumModule.GROUP_PERMISSION,
    label: 'Phân quyền'
  }
]
