import React, { Suspense } from 'react'

// project import
import { Loader } from './Loader'

export const Loadable = (Component) =>
  function (props) {
    return (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    )
  }
