import { ListEntity, UserEntity } from 'models'
import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'

const index = async (params: ApiRequest.user_portal.index): Promise<ListEntity<UserEntity>> => {
  return instanceAxios.get('/users', {
    params
  })
}
const show = async (id: string): Promise<UserEntity> => instanceAxios.get(`/users/${id}`)
const update = async (id: string, data: ApiRequest.user_portal.update) => instanceAxios.patch(`/users/${id}`, data)
export default {
  index,
  show,
  update
}
