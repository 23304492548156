import { AttachmentEntity } from './attachment'
import { EntityBase } from './entity'
import { UserEntity } from './user_portal'

export enum EnumContactMethod {
  PHONE_CALL = 'phone_call',
  PHONE_MESSAGE = 'phone_message',
  ZALO_CALL = 'zalo_call',
  ZALO_MESSAGE = 'zalo_message',
  FACEBOOK = 'facebook'
}
export enum EnumContactFailReason {
  WRONG_PHONE_NUMBER = 'wrong_phone_number'
}
export const ContactMethodLabel: Record<EnumContactMethod, string> = {
  facebook: 'Facebook',
  phone_call: 'Gọi điện thoại',
  phone_message: 'Nhắn tin',
  zalo_call: 'Gọi zalo',
  zalo_message: 'Nhắn zalo'
}
export const ContactFailReasonLabel: Record<EnumContactFailReason, string> = {
  wrong_phone_number: 'SĐT không đúng'
}
export interface CareLogEntity extends EntityBase {
  contact_success: boolean
  fail_reason: EnumContactFailReason
  method: EnumContactMethod
  content: string
  attachments: AttachmentEntity[]
  created_by: UserEntity
  deleted_at: Date
  created_at: Date
}
