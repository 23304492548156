import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { FC } from 'react'

interface Props {
  onChange: (e: any) => void
}
export const UploadButton: FC<Props> = ({ onChange }: Props) => {
  return (
    <span className={['button-upload-file'].join(' ')}>
      <Box
        sx={{
          padding: 1,
          border: '1px dashed #60A5FA',
          borderRadius: '6px',
          background: '#EFF6FF',
          textAlign: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Button component="label" variant="outlined" sx={{ fontSize: '14px', mb: 0.5 }}>
          Chọn file
          <input hidden type="file" onChange={(e) => onChange(e.target.files?.[0])} />
        </Button>
        <Typography fontSize="12px" lineHeight="16px" color="#737373">
          Định dạng xlsx, dưới 10Mb
        </Typography>
      </Box>
    </span>
  )
}
