import { StoreEntity, StoreTypeLabel, TableColumn } from 'models'

const Table: TableColumn<StoreEntity>[] = [
  {
    header: {
      label: 'Tên vị trí'
    },
    cell: {
      key: 'name'
    }
  },
  {
    header: {
      label: 'Đơn vị tổ chức'
    },
    cell: {
      getValue: (a: StoreEntity) => (a.org_unit ? a.org_unit.name : 'N/A'),
      width: 200
    }
  },
  {
    header: {
      label: 'Thương hiệu'
    },
    cell: {
      getValue: (a: StoreEntity) => (a.flag != undefined ? StoreTypeLabel[a.flag] : '')
    }
  },
  {
    header: {
      label: 'Mã Odoo'
    },
    cell: {
      key: 'odoo_code'
    }
  },
  {
    header: {
      label: 'Vùng'
    },
    cell: {
      getValue: (a: StoreEntity) => a.area?.name ?? ''
    }
  },
  {
    header: {
      label: 'Địa chỉ'
    },
    cell: {
      key: 'address'
    }
  }
]

export default Table
