import React from 'react'

export function DetailIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.83334 14.666C1.83334 14.3899 2.0572 14.166 2.33334 14.166H13.6667C13.9428 14.166 14.1667 14.3899 14.1667 14.666C14.1667 14.9422 13.9428 15.166 13.6667 15.166H2.33334C2.0572 15.166 1.83334 14.9422 1.83334 14.666Z"
        fill="#525252"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0202 1.98043C12.8249 1.78517 12.5084 1.78517 12.3131 1.98043L2.97977 11.3138C2.78451 11.509 2.78451 11.8256 2.97977 12.0209C3.17503 12.2161 3.49161 12.2161 3.68688 12.0209L13.0202 2.68754C13.2155 2.49228 13.2155 2.17569 13.0202 1.98043Z"
        fill="#525252"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1667 2.33398C13.1667 2.05784 12.9428 1.83398 12.6667 1.83398H5.81999C5.54385 1.83398 5.31999 2.05784 5.31999 2.33398C5.31999 2.61013 5.54385 2.83398 5.81999 2.83398H12.1667V9.18065C12.1667 9.45679 12.3905 9.68065 12.6667 9.68065C12.9428 9.68065 13.1667 9.45679 13.1667 9.18065V2.33398Z"
        fill="#525252"
      />
    </svg>
  )
}
