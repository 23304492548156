import { EntityBase } from './entity'
import { StoreEntity } from './store'
import { UserEntity } from './user_portal'

export enum EnumOrgUnitType {
  // Khối: gồm nhiều phòng
  DIVISION = 'division',
  // Vùng: gồm nhiều cửa hàng
  BUSINESS_UNIT = 'business_unit',
  // Cửa hàng
  STORE = 'store',
  // Phòng ban BO: gồm nhiều team
  DEPARTMENT = 'department',
  // Team/nhóm nhỏ
  TEAM = 'team'
}
// 1. create interface
export interface OrgUnitEntity extends EntityBase {
  name: string

  type: EnumOrgUnitType
  leader?: UserEntity
  children?: OrgUnitEntity[]
  parent?: OrgUnitEntity
  mapping_store?: StoreEntity
  users?: UserEntity[]
  level: number
  deleted_at: Date
}
