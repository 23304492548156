import { EntityBase } from 'models/entity'
import { EnumModule } from './module'

export interface PermissionEntity extends EntityBase {
  module: EnumModule
  action: EnumAction
  label?: string
}
export enum EnumAction {
  CREATE = 'create',
  UPDATE = 'update',
  VIEW = 'view',
  DELETE = 'delete',
  SYNC_PORTAL = 'sync_portal',
  CAMPAIGN_IMPORT_CUSTOMER = 'campaign_import_customer',
  CAMPAIGN_DISTRIBUTE_CUSTOMER = 'campaign_distribute_customer',
  CAMPAIGN_CARE_CUSTOMER = 'campaign_care_customer'
}

export const ALL_PERMISSIONS: Partial<PermissionEntity>[] = [
  // Module Chiến dịch
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.VIEW,
    label: 'Xem'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CREATE,
    label: 'Tạo mới'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.UPDATE,
    label: 'Chỉnh sửa'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.DELETE,
    label: 'Xoá'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_IMPORT_CUSTOMER,
    label: 'Nhập thông tin khách hàng'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_DISTRIBUTE_CUSTOMER,
    label: 'Phân phối khách hàng'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_CARE_CUSTOMER,
    label: 'Chăm sóc khách hàng'
  },
  // Module Chăm sóc khách hàng
  {
    module: EnumModule.CUSTOMER_CARE,
    action: EnumAction.VIEW,
    label: 'Xem / thống kê'
  },
  // Module Khách hàng
  {
    module: EnumModule.CUSTOMER,
    action: EnumAction.VIEW,
    label: 'Xem thông tin'
  },
  // Module Nhân viên
  {
    module: EnumModule.USER,
    action: EnumAction.VIEW,
    label: 'Xem'
  },
  {
    module: EnumModule.USER,
    action: EnumAction.SYNC_PORTAL,
    label: 'Đồng bộ portal'
  },
  // Module Cơ cấu tổ chức
  {
    module: EnumModule.ORG_UNIT,
    action: EnumAction.VIEW,
    label: 'Xem'
  },
  {
    module: EnumModule.ORG_UNIT,
    action: EnumAction.SYNC_PORTAL,
    label: 'Đồng bộ portal'
  },
  // Module Vị trí
  {
    module: EnumModule.LOCATION,
    action: EnumAction.VIEW,
    label: 'Xem'
  },
  {
    module: EnumModule.LOCATION,
    action: EnumAction.SYNC_PORTAL,
    label: 'Đồng bộ portal'
  },
  // Module Phân quyền
  {
    module: EnumModule.GROUP_PERMISSION,
    action: EnumAction.CREATE,
    label: 'Tạo mới'
  },
  {
    module: EnumModule.GROUP_PERMISSION,
    action: EnumAction.VIEW,
    label: 'Xem'
  },
  {
    module: EnumModule.GROUP_PERMISSION,
    action: EnumAction.DELETE,
    label: 'Xoá'
  },
  {
    module: EnumModule.GROUP_PERMISSION,
    action: EnumAction.UPDATE,
    label: 'Cập nhật'
  }
]
